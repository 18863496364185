import React, { useState, useEffect, Fragment, useImperativeHandle } from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache, gql, useQuery } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
//import { ApolloClient as ApolloClientCore } from '@apollo/client/core';
import './styles.css';
import Chart from 'chart.js/auto';

import { A, navigate, useQueryParams, useRoutes, usePath } from 'hookrouter';


import createPersistedState from 'use-persisted-state';
const useShoppingBasketState = createPersistedState('shopping_basket');


const GRAPHQL_ENDPOINT = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? 'http://localhost:8080/v1/graphql' : '/v1/graphql';

const httpLink = createHttpLink({
    uri: GRAPHQL_ENDPOINT,
});

const jwt = (document.cookie
    .split('; ')
    .find(row => row.startsWith('adeljwt=')) || '='
    ).split('=')[1];
const jwt_exp = 
    JSON.parse(
        atob(jwt.split('.')[1] || '') || '{}'
    ).exp || 0;
const authed = (jwt_exp*1000) > (new Date().getTime());
const authLink = authed ? setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: `Bearer ${jwt}`,
        }
    }
}) : null;

const client = new ApolloClient({
    link: authLink ? authLink.concat(httpLink) : httpLink, 
    cache: new InMemoryCache()
});

// window.gql = gql;

// window.apolloClient = new ApolloClientCore({
//     uri: 'http://localhost:8080/v1/graphql',
//     cache: new InMemoryCache()
// });


/* random utils */

function formatLargeNumber(x) {
    if(x==null) return '';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function debounce(f) {
    var state = {'timer':null};
    return (...args)=>{
        if(state.timer) {
            clearTimeout(state.timer);
        }
        state.timer = setTimeout(()=>{
            f(...args);
        }, 250);
    };
}


function LoginLink(props) {
    return <a href="/login/" className="text-adelicious_green hover:underline">Login to view</a>;
}


function DrawChart(item) {
    var ctx = document.getElementById('myChart');
    var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: ['0-17', '18-22', '23-27', '28-34', '35-44', '45-59', '60+'],
            datasets: [{
                label: 'Audience age distribution',
                data: [
                    item.audience_percentage_age_0_17,
                    item.audience_percentage_age_18_22,
                    item.audience_percentage_age_23_27,
                    item.audience_percentage_age_28_34,
                    item.audience_percentage_age_35_44,
                    item.audience_percentage_age_45_59,
                    item.audience_percentage_age_60_plus
                ],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                ],
                borderWidth: 1
            }]
        },
        options: {
            scales: {
                y: {
                    ticks: {
                        callback: function(value, index, values) {
                            return value + '%';
                        }
                    },
                    beginAtZero: true
                }
            },
            plugins: {
                tooltip: {
                    callbacks: {

                    }
                }
            }

        }
    });
}

function generateFilterURL(filter) {
    var href = '/';
    var slug_bits = [];
    ['category', 'location', 'gender'].forEach(k=>{
        if(filter[k]) {
            slug_bits.push(k + '_' + filter[k]);
        }
    });
    if(slug_bits.length) {
        href = '/filter/' + slug_bits.join('_') + '/';
    }
    if(filter.q) {
        href += '?q=' + encodeURIComponent(filter.q);
    }
    return href;
}

function FilterA(props) {
    const [queryParams, setQueryParams] = useQueryParams();
    // var path = usePath();
    // console.log('path is', path);
    var href = generateFilterURL(props.filter || {});

    var onClick = (ev)=>{
        ev.preventDefault();

        navigate(href);
        setQueryParams({q: (props.filter || {}).q || undefined}, true);
    };

    return (
        <a onClick={ onClick } href={ href } className={ props.className }>{ props.children }</a>
    );
}

function Rating(props) {
    return (
        <div className="flex flex-row">
            {[...Array(props.value).keys()].map(x =>
                <svg key={x} xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-gray-600" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                </svg>)
            }
        </div>
    )
}

function YesNo({value}) {
    return value ? 
        <span className="text-adelicious_green">{ '\u2714' }</span> 
        : <span className="text-adelicious_salmon">{ '\u2717' }</span>;
    return (
        value ?
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" fill="#00c300" /></svg>
            : <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M24 20.188l-8.315-8.209 8.2-8.282-3.697-3.697-8.212 8.318-8.31-8.203-3.666 3.666 8.321 8.24-8.206 8.313 3.666 3.666 8.237-8.318 8.285 8.203z" fill="#c30000"/></svg>
    )
}

function Pill(props) {
    return (
        <FilterA filter={ props.filter }>
            <div key={props.text} className="flex whitespace-no-wrap bg-adelicious_green text-black px-2 py-1 font-bold rounded-sm mr-1 mb-1">
                #{props.text}
            </div>
        </FilterA>
    )
}

function AddToBasket(props) {
    const [basket, setBasket] = useShoppingBasketState({});

    function addToBasket() {
        setBasket(Object.assign({}, basket, {
            [props.item.slug]:{
                slug: props.item.slug,
                title: props.item.title
            }
        }));
    }

    function removeFromBasket() {
        var b = Object.assign({}, basket);
        delete b[props.item.slug];
        setBasket(b);
    }

    return (

        <div className="bg-white p-6 my-5 rounded-md relative">
            <h3 className="text-2xl text-gray-900 font-semibold">Add to basket</h3>
            <p>Click the button below to add this podcast to <A href="/basket/" className="underline">your basket</A>.</p>
            { basket[props.item.slug] ? 
                <React.Fragment>
                    <button 
                        type="button" 
                        className="rounded-md w-1/4 mt-6 bg-gray-200 text-gray-500 font-semibold p-3"
                        disabled
                        >Added to basket</button>
                    <button 
                        type="button" 
                        className="rounded-md w-1/4 ml-3 mt-6 bg-red-600 text-white font-semibold p-3"
                        onClick={removeFromBasket}
                        >Remove from basket</button>
                </React.Fragment>
            :   <button 
                    type="button" 
                    className="rounded-md w-1/4 mt-6 bg-green-500 hover:bg-green-400 text-white font-semibold p-3"
                    onClick={addToBasket}
                    >Add to basket</button>
            }
        </div>
    )
}

function ContactForm(props) {
    const [fields, setFields] = useState({});
    const [message, setMessage] = useState("");

    function fieldChange(k, ev) {
        setFields(Object.assign({}, fields, {
            [k]: ev.target.value
        }));
    }

    function handleSubmit(ev) {
        ev.preventDefault();
        var data = Object.assign(fields, {
            'basket':Object.values(props.basket)
        });

        fetch('/api/enquire/', {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
              'Content-Type': 'application/json'
            },
            redirect: 'follow', // manual, *follow, error
            body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).then(r=>r.json()).then(r=>{
            setMessage(r.detail);
            setFields({});
            if(props.onEnquireSuccess) props.onEnquireSuccess();
        });
        

    }


    return (
        <form action="" className="form bg-white text-black p-6 my-5 rounded-md relative" onSubmit={ handleSubmit }>
            <h3 className="text-2xl text-gray-900 font-semibold">Make Enquiry</h3>
            <p className="text-gray-600"> To help you choose your ad slot</p>

            { message && <div className="rounded-md p-4 my-4 bg-green-500 font-semibold text-white">{ message }</div> }

            <div className="flex mt-3 flex-wrap -mx-2">
                <input type="text" value={fields.name||''} onChange={fieldChange.bind(this, 'name')} placeholder="Your Name" required className="border p-2 m-2 flex-auto" />
                <input type="tel" value={fields.telephone||''} onChange={fieldChange.bind(this, 'telephone')} id="" placeholder="Your Number" required className="border p-2 m-2 flex-auto" />
            </div>
            <input type="email" value={fields.email||''} onChange={fieldChange.bind(this, 'email')} placeholder="Your Email" required className="border p-2 w-full my-2" />
            <textarea name="" id="" cols="10" rows="3" value={fields.enquiry||''} onChange={fieldChange.bind(this, 'enquiry')} placeholder="Tell us about your desired ad slot" className="border p-2 my-2 w-full"></textarea>
            <p className="font-bold text-sm mt-2">GDPR Agreement *</p>
            <div className="flex items-baseline space-x-2 mt-2">
                <input type="checkbox" name="" id="id_agree_gdpr" required className="inline-block" />
                <label className="text-gray-600 text-sm" htmlFor="id_agree_gdpr">I consent to having this website store my submitted information so they can respond to my inquiry.</label>
            </div>
            <input type="submit" value="Submit" className="rounded-md w-full mt-6 bg-blue-600 hover:bg-blue-500 text-white font-semibold p-3" />
        </form>
    )
}

function AudiencePercentageChart(props) {
    var item = props.item;   
    var rows = [
        ['0-17', item.audience_percentage_age_0_17],
        ['18-22', item.audience_percentage_age_18_22],
        ['23.27', item.audience_percentage_age_23_27],
        ['28-34', item.audience_percentage_age_28_34],
        ['35-44', item.audience_percentage_age_35_44],
        ['45-59', item.audience_percentage_age_45_59],
        ['60+', item.audience_percentage_age_60_plus]];

    var scale = 80/Math.max(...rows.map(i=>i[1]));

    return (
        <dl className="w-full mt-2 grid grid-cols-horiz-bar items-center">
            { rows.map(([k,v])=>(
                <React.Fragment key={k}>
                    <dt className="text-caps-sm">{ k }</dt>
                    <dl className="bg-adelicious_green h-2" style={ {width:(v*scale) + '%'} }></dl>
                </React.Fragment>
            )) }
        </dl>
    );
}

function Details(props) {
    var item = props.item;

    var statRow = (label, value)=>(
        <div className="flex w-full text-caps-sm py-2">
            <div className="flex">
                { label }: &nbsp;
            </div>
            <div className="flex font-bold">
                { authLink==null ?
                    <LoginLink />
                :
                    ((value===true || value===false) ?
                        <YesNo value={ value } />
                        : value
                    )
                }
            </div>
        </div>
    );


    return (
        <div className="flex w-full p-4 mb-4 flex-wrap divide-y divide-adelicious_green_dark">


            { !!item.weekly_listens ?
                <div className="text-caps-sm flex w-full py-2">
                    Weekly listens: &nbsp; <span className="font-bold">{ formatLargeNumber(item.weekly_listens) }</span>
                </div> 
                :
                <div className="text-caps-sm flex w-full py-2">
                    Weekly listens: &nbsp; <span className="font-bold"><LoginLink /></span>
                </div> 
            }


            { item.availability && <div className="text-caps-sm flex w-full py-2">
                Status: &nbsp; <span className={ props.item.availability=='Limited' ? "text-adelicious_salmon font-bold" : "text-adelicious_green font-bold"}>{ props.item.availability }</span>
            </div> }

            { item.audience_percentage_uk!=null && statRow('Region breakdown', item.audience_percentage_uk  + '% UK / ' + item.audience_percentage_us + '% US') }

            { statRow('Offers host reads', item.offers_host_reads)}
            { statRow('Dynamic', item.offers_dynamic)}
            
            { item.target_age_min>0 && item.target_age_max>0 && 
                statRow('Target age', item.target_age_min + ' - ' + item.target_age_max)
            }


            {/*}
            <div className="flex flex-row w-1/3">
                <div className="flex">
                    Pre Roll Cost:
                </div>
                <div className="flex">
                    {<Rating value={2} />}
                </div>
            </div>

            <div className="flex flex-row w-1/3">
                <div className="flex">
                    Mid Roll Cost:
                </div>
                <div className="flex">
                    {<Rating value={4} />}
                </div>
            </div>

            <div className="flex flex-row w-1/3">
                <div className="flex">
                    Post Roll Cost:
                </div>
                <div className="flex">
                    {<Rating value={2} />}
                </div>
            </div>

            <div className="flex flex-row w-1/3">
                <div className="flex">
                    Reach:
                </div>
                <div className="flex">
                    {<Rating value={5} />}
                </div>
            </div>*/ }

            { item.audience_bias !== null && item.audience_bias !== undefined && (
                item.audience_bias >= 50 ?
                    statRow('Gender breakdown', item.audience_bias + '% Female')
                    : statRow('Gender breakdown', (100-item.audience_bias) + '% Male')
                )
            }

            { item.audience_percentage_age_0_17 != null && 
                (authLink==null ? 
                    statRow('Audience distribution', '')
                    : <div className="w-full text-caps-sm py-2">
                        <div>Age distribution:</div>
                        <AudiencePercentageChart item={item} />
                    </div> 
                )
            }

        </div>
    )
}

function PodcastInfo(props) {
    const { loading, error, data } = useQuery(GET_PODCASTS_BY_SLUG, {
        variables: {
            slugs: [props.slug]
        },
        fetchPolicy: "cache-and-network"
    })
    const [basket, setBasket] = useShoppingBasketState({});


    if (loading) return ''
    if (error) return `Error! ${error.message}`


    var item = data.podcast[0];


    function addToBasket() {
        setBasket(Object.assign({}, basket, {
            [item.slug]:{
                slug: item.slug,
                title: item.title
            }
        }));
    }
    function removeFromBasket() {
        var b = Object.assign({}, basket);
        delete b[item.slug];
        setBasket(b);
    }

    function enquire() {
        addToBasket();
        navigate('/basket/');
    }


    return (
        <div className="flex w-full flex-col pb-12">
            <div className="pt-3 pb-4"><a href="#" onClick={ (ev)=>{ev.preventDefault(); window.history.go(-1)} } className="text-caps-sm text-adelicious_green hover:underline">&lt; Back to list</a></div>
            <div className="md:px-36">
                <div className="flex-row">

                    <div className="py-1 mb-4" style={ {height: '260px'}}>
                        <div className="relative">
                            <img className="rounded-md shadow-lg absolute" style={ {left: '33.333%', width: '33.333%'} } alt="Podcast Artwork" src={item.artwork_url || "/placeholder.png"} />
                        </div>

                        <div className="flex bg-white rounded-md text-black" style={ {marginTop: '80px'}}>
                            <div className="w-1/3 px-1 py-5 text-center">
                                <p className="text-sm font-normal">Interested in multiple shows?</p>

                                { basket[item.slug] ? 
                                    <button 
                                        type="button" 
                                        className="rounded-md mt-3 bg-gray-200 text-gray-500 text-caps px-3 py-1"
                                        disabled
                                        >Added to basket</button>
                                :   <button 
                                        type="button" 
                                        className="rounded-md mt-3 bg-adelicious_green text-caps px-3 py-1"
                                        onClick={addToBasket}
                                        >Add to basket</button>
                                }

                            </div>
                            <div className="w-1/3"></div>
                            <div className="w-1/3 px-1 py-5 text-center">
                                <p className="text-sm font-normal">Interested in just this show?</p>
                                <button 
                                        type="button" 
                                        className="rounded-md mt-3 bg-adelicious_salmon text-caps px-3 py-1"
                                        onClick={enquire}
                                        >Get in touch</button>
                            </div>
                        </div>
                    </div>

                    <div className="p-2 md:p-8 mb-4">
                        <div className="text-4xl font-normal w-full text-center">
                            <A href={"/podcast/" + item.slug +  "/"}>
                                <p>{item.title}</p>
                            </A>
                        </div>

                        <div className="flex flex-row flex-wrap text-xs justify-center mt-6 mb-3 w-full">
                            {
                                item.categories.map(t => 
                                    <Pill 
                                        key={t.category.name} 
                                        text={t.category.name} 
                                        filter={ {'category':t.category.slug} }
                                        />)
                            }
                        </div>

                        <div className="text-sm mt-6 px-6 text-center">
                            <p>
                                {item.description}
                            </p>
                        </div>

                    </div>

                    
                </div>
                <div className="flex-row">
                    <Details item={item} />
                    
                </div>
            </div>
        </div>
    )
}

function Reveal(props) {
    const [loaded, setLoaded] = useState(false);
    useEffect(() => {
        setTimeout(()=>setLoaded(true), 50);
    });

    return (
        <div 
            className={props.className + ' reveal' + (loaded?' in':'')} 
            style={ {transitionDelay: (0.1 + (0.15*props.index)) + 's'} }>
            { props.children }
        </div>
    );
}

function Card(props) {
    return (
        <Reveal className="flex flex-row flex-wrap w-full py-8 md:py-16" index={props.index}>
            <div className="w-1/2 md:w-1/4 p-4 pb-6 md:p-0 pt-0">
                <A href={"/podcast/" + props.item.slug +  "/"}>
                    { props.item.artwork_url ?
                        <img className="rounded-md" alt="Podcast Artwork" src={props.item.artwork_url} />
                      : <img className="rounded-md" alt="Podcast Artwork" src="/placeholder.png" /> }
                </A>
                {/* <div className="flex text-sm w-full justify-end">
                    <p className="text-gray-600">30 episodes</p>
                </div> */}
                
            </div>
            <div className="py-1 px-1 pl-4 md:pl-8 rounded-md flex-col md:w-3/4">
                <div className="flex text-2xl md:text-4xl font-normal w-full">
                    <A href={"/podcast/" + props.item.slug +  "/"}>
                        <p>{props.item.title}</p>
                    </A>
                </div>

                <div className="flex flex-row flex-wrap text-xs mt-5 mb-3 w-full">
                    {
                        props.item.categories.map(t => 
                            <Pill 
                                key={t.category.name} 
                                text={t.category.name} 
                                filter={ {'category':t.category.slug} }
                                />)
                    }
                </div>

                { !!props.item.weekly_listens ?
                    <div className="text-caps-sm flex">
                        Weekly listens: &nbsp; <span className="font-bold">{ formatLargeNumber(props.item.weekly_listens) }</span>
                    </div> 
                    :
                    <div className="text-caps-sm flex">
                        Weekly listens: &nbsp; <span className="font-bold"><LoginLink /></span>
                    </div> 
                }

                { props.item.availability && <div className="text-caps-sm flex tracking-wide">
                    Status: &nbsp; <span className={ props.item.availability=='Limited' ? "text-adelicious_salmon font-bold" : "text-adelicious_green font-bold"}>{ props.item.availability }</span>
                </div> }



                <div className="flex text-sm mt-3 w-full md:w-4/5">
                    <p className={ props.full ? "" : "truncate-6-lines md:truncate-5-lines" }>
                        {props.item.description}
                    </p>
                </div>

                { !props.full && <div className="flex text-sm mt-5 w-4/5">
                    <A 
                        href={"/podcast/" + props.item.slug +  "/"}
                        className="rounded-md bg-adelicious_salmon font-semibold text-caps text-black px-9 py-2"
                        >Learn more
                    </A>
                </div> }
                
            </div>
        </Reveal>
    )
}

/*
        $pre_roll_cost_min: numeric = 0, 
        $pre_roll_cost_max: numeric = 1000000

pre_roll_cost: {
                    _gte: $pre_roll_cost_min,
                    _lt: $pre_roll_cost_max
                }
                */


const MEMBER_ONLY_FIELDS = authLink ? 'weekly_listens' : '';
            
const PODCAST_FIELDS = gql`
    fragment podcast_fields on podcast {
        id
        slug
        title
        description
        artwork_url
        categories {
            category {
                name
                slug
            }
        }

        ${MEMBER_ONLY_FIELDS}
        availability
        audience_bias
        audience_percentage_uk
        audience_percentage_us
        target_age_min
        target_age_max

        releases_on_monday
        releases_on_tuesday
        releases_on_wednesday
        releases_on_thursday
        releases_on_friday
        releases_on_saturday
        releases_on_sunday
        releases_bonuses

        offers_host_reads
        offers_host_voice_over
        offers_spot_ads
        offers_programmatic
        offers_dynamic
        offers_baked_in
        offers_pre_roll_length
        offers_mid_roll_length

        audience_percentage_age_0_17
        audience_percentage_age_18_22
        audience_percentage_age_23_27
        audience_percentage_age_28_34
        audience_percentage_age_35_44
        audience_percentage_age_45_59
        audience_percentage_age_60_plus
    }
`;

/* { 
                _or: [
                    {title: {_ilike: $title}},
                    {description: {_ilike: $title}},
                ],
                categories: {
                    category: {
                        slug: {_eq: $category}
                    }
                }
            } */

const GET_CATEGORIES = gql`
    query GetCategories {
        category(order_by: {name: asc}) {
            name
            slug
        }
    }      
`;

const GET_PODCASTS = gql`
    query GetPodcasts(
        $limit: Int = 10, 
        $offset: Int = 0, 
        $query: podcast_bool_exp
    ) {
        podcast_aggregate(
            where: $query
        ) {
            aggregate {
               totalCount: count
            }
        }
        podcast(
            limit: $limit, 
            offset: $offset,
            where: $query,
            order_by: {rank: asc_nulls_last},
        ) {
            ...podcast_fields
        }
    }       
    ${PODCAST_FIELDS}
`;

var GET_PODCASTS_BY_SLUG = gql`
    query GetPodcastBySlug(
        $slugs: [String]
    ) {
        podcast(
            where: { 
                slug: {_in: $slugs}
            }
        ) {
            ...podcast_fields
        }
    }
    ${PODCAST_FIELDS}
`;

function Search(props) {

    function handleKeyUp(e) {
        props.setQuery(e.target.value)
    }
    //autoFocus
    return (
        <input className="bg-gray-200 appearance-none border-2 border-gray-200 rounded w-full py-0 px-3 text-sm text-gray-700 leading-tight focus:outline-none focus:bg-white h-8" id="inline-search" type="text" placeholder="Enter a search term..." value={props.query} onChange={handleKeyUp} />
    )
}

/*
<div className="w-1/5">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Price
                        </label>
                        <div className="relative text-xs">
                            <select 
                                    placeholder="Price" 
                                    className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                    id="grid-state" 
                                    value={ props.filters.price }
                                    onChange={ (ev)=>{ props.onChange && props.onChange('price', ev.currentTarget.value) }}
                                >
                                <option value="1">£</option>
                                <option value="2">££</option>
                                <option value="3">£££</option>
                                <option value="4">££££</option>
                                <option value="5">£££££</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="w-1/5">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Series
                        </label>
                        <div className="relative text-xs">
                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state">
                                <option>Y</option>
                                <option>N</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-full">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                            Age
                        </label>
                        <div className="relative text-xs">
                            <button className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded m-1">18-22</button><button className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded m-1">23-27</button><button className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded m-1">28-34</button><button className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded m-1">35-44</button><button className="bg-gray-300 hover:bg-gray-400 text-gray-800 py-2 px-4 rounded m-1">45-59</button>
                        </div>
                    </div>
                </div>
*/

function Filter(props) {

    const { loading, error, data } = useQuery(GET_CATEGORIES, {
        fetchPolicy: "cache-and-network"
    });

    var categories = (data && data.category) ? data.category : [];
    var locations = [
        {slug:'US', name:'US'},
        {slug:'UK', name:'UK'}
    ];

    return (
        <div className="w-full pt-4 pb-5 border-b border-adelicious_green_dark">
            <div className="flex flex-row">
                <div className="w-2/5">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="category-filter">
                            Genre
                        </label>
                        <div className="relative text-xs">
                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 h-8 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                id="category-filter"
                                value={ props.filters.category || '' }
                                onChange={ (ev)=>{ props.onChange && props.onChange('category', ev.currentTarget.value) } }
                                >
                                <option value="">All</option>
                                { categories.map(c=>(
                                    <option key={c.slug} value={c.slug}>{ c.name }</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/4">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="category-filter">
                            Location
                        </label>
                        <div className="relative text-xs">
                            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 h-8 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" 
                                id="category-filter"
                                value={ props.filters.location || '' }
                                onChange={ (ev)=>{ props.onChange && props.onChange('location', ev.currentTarget.value) } }
                                >
                                <option value="">All</option>
                                { locations.map(c=>(
                                    <option key={c.slug} value={c.slug}>{ c.name }</option>
                                ))}
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-1/4">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="gender-filter">
                            Gender
                        </label>
                        <div className="relative text-xs">
                            <select 
                                className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 h-8 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="gender-filter"
                                value={ props.filters.gender || '' }
                                onChange={ (ev)=>{ props.onChange && props.onChange('gender', ev.currentTarget.value) } }
                                >
                                <option value="">All</option>
                                <option value="M">M</option>
                                <option value="F">F</option>
                            </select>
                            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="w-2/5">
                    <div className="w-full px-3">
                        <label className="block uppercase tracking-wide text-xs font-bold mb-2" htmlFor="search-input">
                            Search
                        </label>
                        <Search setQuery={ (v)=>{ props.onChange && props.onChange('q', v) } } query={ props.filters.q || '' } />
                    </div>
                </div>
            </div>
        </div>
    )
}

function Paginator(props) {
    var offset = props.offset;
    var limit = props.limit;
    var prevEnabled = (offset - limit)>=0;
    var nextEnabled = ((offset+limit) < props.totalCount);
    var setOffset = props.setOffset;

    return (
        <Fragment>
            <button    
                className="bg-adelicious_green disabled:opacity-50 text-gray-800 text-caps py-2 px-4 rounded mr-4" 
                disabled={!prevEnabled}
                onClick={() => setOffset(Math.max(offset - limit, 0))}>
                Prev
            </button>
            <button 
                className="bg-adelicious_green disabled:opacity-50 text-gray-800 text-caps py-2 px-4 rounded" 
                disabled={!nextEnabled}
                onClick={() => nextEnabled && setOffset(offset + limit)}>
                Next
            </button>
        </Fragment>
    );
}

function Browse(props) {
    const [queryParams, setQueryParams] = useQueryParams();
    const path = usePath();
    
    const [cachedData, setCachedData] = useState({
        podcast: [],
        podcast_aggregate: {
            aggregate: {
                totalCount: 0
            }
        }
    })

    var offset = queryParams.offset||0;
    var setOffset = i=>setQueryParams({offset:i});

    var filters = props.filter || {};
    filters.q = queryParams.q||'';

    var setFilters = (f)=>{
        navigate(generateFilterURL(f));//, false, {q: f.q || undefined});
        setQueryParams({q: f.q || undefined}, true);
    };

    console.log('Browse, filters is', filters);

    const limit = 10

    var podcastQuery = {};

    if(filters.q) {
        podcastQuery['_or'] = [
            {title: {_ilike: "%" + filters.q + "%"}},
            {description: {_ilike: "%" + filters.q + "%"}},
        ];
    }

    if(filters.category) {
        podcastQuery.categories = {
            category: {
                slug: {_eq: filters.category},
            }
        };
    };
   
    if(filters.gender) {
        podcastQuery.audience_bias = filters.gender==='F' ? {_gt:50} : {_lt:50};
    }

    if(filters.location==='US') {
        podcastQuery.audience_percentage_us = {_gt:40};
    } else if(filters.location==='UK') {
        podcastQuery.audience_percentage_uk = {_gt:50};
    }

    const { loading, error, data } = useQuery(GET_PODCASTS, {
        variables: {
            offset: offset,
            limit: limit,
            query: podcastQuery
        },
        fetchPolicy: "cache-and-network"
    })

    if(!loading && data!=cachedData) {
        setCachedData(data);

        window.scrollTo(0, 0);
    }

    //if (loading && !data) return 'Loading...';
    if (error) return `Error! ${error.message}`;

    // fish the category out of one of the podcasts
    var category = props.category ? (cachedData.podcast.map(
        p=>p.categories.map(i=>i.category).filter(i=>i.slug===props.category)
    ).filter(i=>i.length)[0] || [])[0] : null;


    return (
        <Fragment>
            { category &&
                <div className="flex-col rounded-b-lg shadow-md w-full p-4 mb-5 bg-white">
                    <h2 className="text-3xl font-bold">Podcasts in the "{ category.name }" category</h2>
                </div>
            }
            <Filter 
                filters={filters} 
                onChange={ (k, v)=>{ setFilters(Object.assign({}, filters, {[k]:v})); } } />
            <div id="cards" className="flex flex-col w-full place-content-center divide-y divide-adelicious_green_dark">
                {cachedData.podcast.map((item, k) => <Card key={path + '|' + item.id} index={k} item={item} />)}
                {cachedData.podcast.length===0 && !loading && 
                    <p className="py-6">No matching podcasts found.</p>
                }
            </div>
            <div className="flex place-content-center w-full p-4 mb-10">
                {!loading && <Paginator 
                    offset={offset} 
                    limit={limit} 
                    totalCount={cachedData.podcast_aggregate.aggregate.totalCount}
                    setOffset={setOffset}
                    /> }
            </div>
        </Fragment>
    )
}

function ViewBasket() {
    const [basket, setBasket] = useShoppingBasketState({});

    const { loading, error, data } = useQuery(GET_PODCASTS_BY_SLUG, {
        variables: {
            slugs: Object.keys(basket)
        },
        fetchPolicy: "cache-and-network"
    })

    console.log(loading, error, data);

    function removeFromBasket(item) {
        var b = Object.assign({}, basket);
        delete b[item.slug];
        setBasket(b);
    }

    useEffect(()=>{
        if(!loading && Object.keys(basket).length) {
            //remove things from the basket that are no longer in the db
            var fetched_slugs = data.podcast.map(item=>item.slug);
            var cleaned_basket = {};
            Object.keys(basket).forEach(b=>{
                if(fetched_slugs.indexOf(b)>-1) {
                    cleaned_basket[b] = basket[b];
                }
            });
            if(Object.keys(cleaned_basket).length!=Object.keys(basket).length) {
                setBasket(cleaned_basket);
            }
        }
    });

    return (<React.Fragment>
        <div className="pt-3 pb-4"><FilterA className="text-caps-sm text-adelicious_green hover:underline">&lt; Back to list</FilterA></div>
        <div className="my-4 md:my-10">
            <h3 className="text-3xl md:text-4xl text-adelicious_salmon font-normal text-center">View basket</h3>
        </div>
        <div id="cards" className="flex flex-col px-4 md:px-32 w-full place-content-center divide-y divide-adelicious_green_dark">
            {!!data && data.podcast.map((item) => <div className="w-full flex py-2" key={item.slug}>
                <div className="w-14 m-2"><img className="rounded-md w-full" alt="Podcast Artwork" src={item.artwork_url || "/placeholder.png"} /></div>
                <div className="flex-auto text-white text-caps self-center px-4">{ item.title }</div>
                <div className="self-center"><a className="text-caps text-adelicious_green_dark" onClick={ (ev)=>{ev.preventDefault(); removeFromBasket(item);} } href="#">Remove</a></div>
            </div>)}
            {!!data && data.podcast.length===0 && !loading && <p>There are no items in your basket.</p>}
        </div>
        <div className="px-4 md:px-32">
            <ContactForm basket={ basket } onEnquireSuccess={ ()=>setBasket({}) }/>
        </div>
        </React.Fragment>

    );

}

function Navbar() {
    const [basket, setBasket] = useShoppingBasketState({});
    
    return (
        <nav className="flex items-center justify-between flex-wrap bg-adelicious text-black border-b border-adelicious_green_dark">
            <a href="https://www.adelicious.fm/">
                <div className="flex items-center flex-shrink-0 text-white mr-6 logo">
                    <img src="https://www.adelicious.fm/s/adelicious-logo.svg" width="149" height="49" />
                </div>
            </a>
            <div className="flex flex-row">
                { basket && Object.keys(basket).length>0 && <A href="/basket/"><button className="rounded-md p-2 px-4 text-caps" style={ { 'backgroundColor': '#26f8c9'}}>
                    {Object.keys(basket).length} items in basket
                </button></A> }
                { authLink!=null && <a href="/logout/"><button className="rounded-md p-2 px-4 ml-3 text-caps" style={ { 'backgroundColor': '#ffb2b3'}}>
                    Log out
                </button></a> }
            </div>
        </nav>
    )
}

function parseFilter(slug) {
    var ret = {};
    var bits = slug.split('_');
    for(var i=0;i<bits.length;i+=2) {
        ret[bits[i]] = bits[i+1];
    }
    return ret;
}

function Page() {
    const routes = {
        "/": () => <Browse />,
        "/basket/": () => <ViewBasket />,
        "/filter/:filter/": ({filter}) => <Browse filter={parseFilter(filter)} />,
        "/podcast/:slug/": ({slug}) => <PodcastInfo slug={slug} />,
    };

    return (
        <div className="container px-4">
            <Navbar />
            <div id="main">
                { useRoutes(routes) }
            </div>
        </div>
    )
}
// const routes = {
//     "/": () => <Users />,
//     "/about": () => <About />,
//     "/contact": () => <Contact />
// };

function App() {
    return (
        <div className="flex w-full place-content-center h-full font-light text-white">
            <ApolloProvider client={client}>
                <Page />
            </ApolloProvider>
        </div>
    );
}

var root = document.getElementById('root');
if(root) {
    ReactDOM.render(
        <App />,
        root
    );
}
